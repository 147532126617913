import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {
  Box,
  FormGroup,
  styled,
  Label as SmoothLabel,
  Typography,
  Grid,
  Row,
  Col,
  th
} from '@smooth-ui/core-sc';
import useForm from './useForm';
import Button from 'components/Button';
import Link from 'components/Link';

// Context
import UiContext from 'context/UiContext';

const query = graphql`
  query ContactFormQuery {
    companyInfo: sanityCompanyInfo {
      name
      address1
      address2
      zipCode
      city
      country
      email
      phone
      mobile
      openHours
      closedHours
      facebookUrl
      linkedInUrl
      googleUrl
    }
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 13px 20px;
  margin-bottom: 30px;

  &::placeholder {
    color: ${th('orange')};
  }
`;

const Textarea = styled(Input)`
  height: 100px;
  resize: none;
`;

const Label = ({ children, required, ...rest }) => (
  <SmoothLabel {...rest}>
    {children} {required && <Typography color="red">*</Typography>}
  </SmoothLabel>
);

const Contact = ({ title, image, formName }) => {
  const initialValues = {
    formName,
    fname: '',
    lname: '',
    phone: '',
    email: '',
    message: '',
    privacy: false
  };
  const { success, handleReset, values, handleChange, handleSubmit } = useForm(initialValues);

  return (
    <Box pt={{ xs: 4, lg: 6 }} pb={{ xs: 4, lg: 0 }} backgroundColor="hsla(27, 100%, 86%, 1)">
      <Grid>
        <Row alignItems={success ? 'center' : { md: 'center', lg: 'flex-end' }}>
          <Col md={5}>
            <Box mx="auto" lineHeight={0} fontSize={0}>
              <UiContext.Consumer>
                {({ isIE }) => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 497 700"
                    width={isIE ? '451px' : '100%'}
                    height={isIE ? '636px' : '100%'}
                  >
                    <defs>
                      <path
                        id="mask_2_c"
                        d="M152 50h437.330585L646 336.070312 354.546875 750 149 606.214844z"
                      />
                      <pattern id="mask_2_b" width="103.052%" height="100%" x="-3.052%">
                        <use transform="scale(.93333)" xlinkHref="#mask_2_a" />
                      </pattern>
                      <image id="mask_2_a" width="565" height="750" xlinkHref={image} />
                      <filter
                        id="mask_2_d"
                        width="112.9%"
                        height="109.1%"
                        x="-6.4%"
                        y="-4.6%"
                        filterUnits="objectBoundingBox"
                      >
                        <feGaussianBlur
                          in="SourceAlpha"
                          result="shadowBlurInner1"
                          stdDeviation="4"
                        />
                        <feOffset dy="8" in="shadowBlurInner1" result="shadowOffsetInner1" />
                        <feComposite
                          in="shadowOffsetInner1"
                          in2="SourceAlpha"
                          k2="-1"
                          k3="1"
                          operator="arithmetic"
                          result="shadowInnerInner1"
                        />
                        <feColorMatrix
                          in="shadowInnerInner1"
                          result="shadowMatrixInner1"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                        />
                        <feGaussianBlur
                          in="SourceAlpha"
                          result="shadowBlurInner2"
                          stdDeviation="8"
                        />
                        <feOffset dy="16" in="shadowBlurInner2" result="shadowOffsetInner2" />
                        <feComposite
                          in="shadowOffsetInner2"
                          in2="SourceAlpha"
                          k2="-1"
                          k3="1"
                          operator="arithmetic"
                          result="shadowInnerInner2"
                        />
                        <feColorMatrix
                          in="shadowInnerInner2"
                          result="shadowMatrixInner2"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                        />
                        <feGaussianBlur
                          in="SourceAlpha"
                          result="shadowBlurInner3"
                          stdDeviation="16"
                        />
                        <feOffset dy="32" in="shadowBlurInner3" result="shadowOffsetInner3" />
                        <feComposite
                          in="shadowOffsetInner3"
                          in2="SourceAlpha"
                          k2="-1"
                          k3="1"
                          operator="arithmetic"
                          result="shadowInnerInner3"
                        />
                        <feColorMatrix
                          in="shadowInnerInner3"
                          result="shadowMatrixInner3"
                          values="0 0 0 0 1 0 0 0 0 0.612004029 0 0 0 0 0.285270579 0 0 0 0.2 0"
                        />
                        <feMerge>
                          <feMergeNode in="shadowMatrixInner1" />
                          <feMergeNode in="shadowMatrixInner2" />
                          <feMergeNode in="shadowMatrixInner3" />
                        </feMerge>
                      </filter>
                    </defs>
                    <g fill="none" fillRule="evenodd" transform="translate(-149 -50)">
                      <use fill="url(#mask_2_b)" xlinkHref="#mask_2_c" />
                      <use fill="#000" filter="url(#mask_2_d)" xlinkHref="#mask_2_c" />
                    </g>
                  </svg>
                )}
              </UiContext.Consumer>
            </Box>
          </Col>
          <Col xs={12} omd={1} md={6}>
            {success ? (
              <>
                <Typography
                  textAlign={{ xs: 'center', sm: 'left' }}
                  variant="h1"
                  mt={{ xs: 4, md: 0 }}
                  mb={6}
                >
                  Bedankt, we hebben uw aanvraag goed ontvangen. We nemen zo snel mogelijk contact
                  met u op.
                </Typography>
                <Box textAlign={{ xs: 'center', sm: 'left' }}>
                  <Button onClick={handleReset}>Een nieuw bericht sturen</Button>
                </Box>
              </>
            ) : (
              <>
                <Typography
                  variant="h1"
                  maxWidth={{ md: 390 }}
                  textAlign={{ xs: 'center', sm: 'left' }}
                  mx={{ xs: 'auto', md: 0 }}
                  mt={{ xs: 4, md: 0 }}
                  mb={6}
                >
                  {title}
                </Typography>
                <form onSubmit={handleSubmit} data-netlify="true" name="contact" method="post">
                  <input type="hidden" name="form-name" value={formName} />
                  <FormGroup display="none">
                    <Label htmlFor="formName">Form Name</Label>
                    <Input
                      required
                      display="block"
                      type="text"
                      id="formName"
                      name="formName"
                      value={values.formName}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <Row>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <Label required htmlFor="lname">
                          Naam
                        </Label>
                        <Input
                          required
                          display="block"
                          type="text"
                          id="lname"
                          name="lname"
                          value={values.lname}
                          onChange={handleChange}
                          placeholder="Vul uw naam in"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <Label required htmlFor="fname">
                          Voornaam
                        </Label>
                        <Input
                          required
                          display="block"
                          type="text"
                          id="fname"
                          name="fname"
                          value={values.fname}
                          onChange={handleChange}
                          placeholder="Vul uw voornaam in"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <Label htmlFor="phone">Telefoonnummer</Label>
                        <Input
                          display="block"
                          type="tel"
                          id="phone"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          placeholder="Vul uw nummer in"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <Label required htmlFor="email">
                          E-mailadres
                        </Label>
                        <Input
                          required
                          display="block"
                          type="email"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          placeholder="voorbeeld@mail.be"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="message">Bericht</Label>
                        <Textarea
                          as="textarea"
                          display="block"
                          type="text"
                          id="message"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          placeholder="Laat een bericht achter"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="privacy">
                          <input
                            required
                            type="checkbox"
                            id="privacy"
                            name="privacy"
                            value={values.privacy}
                            onChange={handleChange}
                          />
                          <Typography variant="p" ml={1}>
                            Ik heb notie genomen van en ga akkoord met de{' '}
                            <Link to="/privacy">
                              <Typography style={{ textDecoration: 'underline' }}>
                                Privacy verklaring
                              </Typography>
                            </Link>
                          </Typography>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Box textAlign={{ xs: 'center', sm: 'left' }} mb={8}>
                    <Button type="submit">Verzenden</Button>
                  </Box>
                </form>
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </Box>
  );
};

Contact.defaultProps = {
  formName: 'contact',
  title: 'Neem contact met ons op of maak een afspraak'
};

export default Contact;
