import React from 'react';

import * as S from './Button.css';

const Button = ({ as, accent, brdrColor, children, ...rest }) => {
  return (
    <S.Button as={as} {...rest} display="inline-block">
      <S.Inner px={3} py={2} as="span" display="block" accent={accent} brdrColor={brdrColor}>
        {children}
      </S.Inner>
    </S.Button>
  );
};

Button.defaultProps = {
  as: 'button',
  brdrColor: 'teal20',
  accent: 'teal'
};

export default Button;
