import React from 'react';
import { graphql } from 'gatsby';

// Context
import UiContext from 'context/UiContext';

import { Grid, Row, Col, Box, Typography } from '@smooth-ui/core-sc';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from 'components/Layout';
import Mask from 'components/Mask';
import ContactForm from 'components/Forms/Contact';
import { FaFacebook, FaLinkedinIn, FaGoogle } from 'react-icons/fa';

import Link from 'components/Link';

export const query = graphql`
  query ContactPageQuery {
    page: sanityContactPage {
      title
      contact_map {
        asset {
          small: fixed(toFormat: JPG, width: 800, height: 960) {
            src
          }
          large: fixed(toFormat: JPG, width: 1130, height: 1500) {
            src
          }
        }
      }
      contact {
        title
        image {
          asset {
            fixed(toFormat: JPG, width: 565, height: 750) {
              src
            }
          }
        }
      }
      seo {
        title
        description
        image {
          asset {
            fixed(toFormat: JPG, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
    companyInfo: sanityCompanyInfo {
      name
      address1
      address2
      zipCode
      city
      country
      email
      phone
      mobile
      openHours
      closedHours
      facebookUrl
      linkedInUrl
      googleUrl
    }
  }
`;

const ContactPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const page = data.page;
  const companyInfo = data.companyInfo;

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image.asset.fixed.src}
      />
      <Grid>
        <Row>
          <Col xs={12} md={6}>
            <UiContext.Consumer>
              {({ isIE }) => (
                <Box
                  mt={'-40%'}
                  mb={50}
                  ml={{ xs: '-20px', sm: 0 }}
                  height={isIE ? '100%' : 'auto'}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://goo.gl/maps/JXTod6imNnS2"
                  >
                    <Mask
                      small={page.contact_map.asset.small.src}
                      large={page.contact_map.asset.large.src}
                    />
                  </a>
                </Box>
              )}
            </UiContext.Consumer>
          </Col>

          <Col mt={{ md: 120 }} mb={{ xs: 4, md: 8 }} xs={12} md={6}>
            <Box>
              {companyInfo && (
                <Box width={1}>
                  <Box mb={4}>
                    <Typography display="block" fontWeight="bold">
                      {companyInfo.name}
                    </Typography>
                    <Typography display="block">{companyInfo.address1}</Typography>
                    {companyInfo.address2 && (
                      <Typography display="block">{companyInfo.address2}</Typography>
                    )}
                    <Typography display="block">
                      {companyInfo.zipCode} {companyInfo.city}
                    </Typography>
                  </Box>
                  <Box mb={4}>
                    <Typography display="block" fontWeight="bold">
                      Contact
                    </Typography>
                    <Typography display="block">
                      <a href={`tel:${companyInfo.phone}`}>Tel: {companyInfo.phone}</a>
                    </Typography>
                    <Typography display="block">
                      <a href={`tel:${companyInfo.mobile}`}>GSM: {companyInfo.mobile}</a>
                    </Typography>
                    <Typography display="block">
                      <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
                    </Typography>
                  </Box>
                  <Box mb={4}>
                    <Typography display="block" fontWeight="bold">
                      Openingsuren
                    </Typography>
                    <Typography display="block">{companyInfo.openHours}</Typography>
                    <Typography display="block">{companyInfo.closedHours}</Typography>
                  </Box>
                  <Box>
                    <Row justifyContent="space-between">
                      <Col>
                        {companyInfo.facebookUrl && (
                          <Typography mr={2} as="a" href={companyInfo.facebookUrl}>
                            <FaFacebook />
                          </Typography>
                        )}
                        {companyInfo.linkedInUrl && (
                          <Typography mr={2} as="a" href={companyInfo.linkedInUrl}>
                            <FaLinkedinIn />
                          </Typography>
                        )}
                        {companyInfo.googleUrl && (
                          <Typography as="a" href={companyInfo.googleUrl}>
                            <FaGoogle />
                          </Typography>
                        )}
                      </Col>
                      <Col>
                        <Link to="/privacy">
                          <Typography style={{ textDecoration: 'underline' }}>
                            Privacy verklaring
                          </Typography>
                        </Link>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              )}
            </Box>
          </Col>
        </Row>
      </Grid>
      <ContactForm formName={'Contact'} image={page.contact.image.asset.fixed.src} />
    </Layout>
  );
};
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
};
export default ContactPage;
