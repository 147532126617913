import { Box, styled, th, transition } from '@smooth-ui/core-sc';

export const Button = styled(Box)``;

export const Inner = styled(Box)`
  position: relative;
  display: block;
  font-weight: bold;
  ${transition('color 0.25s, border-color 0.25s')};

  border-color: ${props => th(props.brdrColor)};
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 2px;
  border-bottom-color: ${props => th(props.accent)};
  border-style: solid;

  > * {
    ${transition('color 0.25s')};
  }

  ${Button}:hover &,
  ${Button}:focus & {
    display: block;
    border-color: ${props => th(props.accent)};
  }
`;
