import React from 'react';
import { Box, Breakpoint } from '@smooth-ui/core-sc';

// Context
import UiContext from 'context/UiContext';

const Mask = ({ large, small }) => {
  return (
    <UiContext.Consumer>
      {({ isIE }) => (
        <>
          <Box display={{ xs: 'none', md: 'block' }} height="100%">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 523 835"
              width={isIE ? '550px' : '100%'}
              height={isIE ? '878px' : '100%'}
            >
              <defs>
                <path
                  id="mask_1_b"
                  d="M0 350.467726L350.634082 0 523 172.28279 411.616931 834.8855H0z"
                />
                <pattern id="mask_1_c" width="110.129%" height="100%" x="-10.129%">
                  <use transform="scale(1.11318)" xlinkHref="#mask_1_a" />
                </pattern>
                <image id="mask_1_a" width="565" height="750" xlinkHref={large} />
                <filter
                  id="mask_1_d"
                  width="106.1%"
                  height="103.8%"
                  x="-3.1%"
                  y="-1.9%"
                  filterUnits="objectBoundingBox"
                >
                  <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="4" />
                  <feOffset dy="8" in="shadowBlurInner1" result="shadowOffsetInner1" />
                  <feComposite
                    in="shadowOffsetInner1"
                    in2="SourceAlpha"
                    k2="-1"
                    k3="1"
                    operator="arithmetic"
                    result="shadowInnerInner1"
                  />
                  <feColorMatrix
                    in="shadowInnerInner1"
                    result="shadowMatrixInner1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                  />
                  <feGaussianBlur in="SourceAlpha" result="shadowBlurInner2" stdDeviation="8" />
                  <feOffset dy="16" in="shadowBlurInner2" result="shadowOffsetInner2" />
                  <feComposite
                    in="shadowOffsetInner2"
                    in2="SourceAlpha"
                    k2="-1"
                    k3="1"
                    operator="arithmetic"
                    result="shadowInnerInner2"
                  />
                  <feColorMatrix
                    in="shadowInnerInner2"
                    result="shadowMatrixInner2"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                  />
                  <feMerge>
                    <feMergeNode in="shadowMatrixInner1" />
                    <feMergeNode in="shadowMatrixInner2" />
                  </feMerge>
                </filter>
              </defs>
              <g fill="none" fillRule="evenodd">
                <use fill="url(#mask_1_c)" xlinkHref="#mask_1_b" />
                <use fill="#000" filter="url(#mask_1_d)" xlinkHref="#mask_1_b" />
              </g>
            </svg>
          </Box>

          <Breakpoint down="md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 355 426"
              width={isIE ? '355px' : '100%'}
              height={isIE ? '426px' : '100%'}
            >
              <defs>
                <path id="mask_1_small_c" d="M0 213L213.410671 0 355 146.773437 314.0625 426H0z" />
                <pattern id="mask_1_small_b" width="100%" height="100%">
                  <use transform="scale(1)" xlinkHref="#mask_1_small_a" />
                </pattern>
                <image id="mask_1_small_a" width="355" height="426" xlinkHref={small} />
                <filter
                  id="mask_1_small_d"
                  width="109%"
                  height="107.5%"
                  x="-4.5%"
                  y="-3.8%"
                  filterUnits="objectBoundingBox"
                >
                  <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="4" />
                  <feOffset dy="8" in="shadowBlurInner1" result="shadowOffsetInner1" />
                  <feComposite
                    in="shadowOffsetInner1"
                    in2="SourceAlpha"
                    k2="-1"
                    k3="1"
                    operator="arithmetic"
                    result="shadowInnerInner1"
                  />
                  <feColorMatrix
                    in="shadowInnerInner1"
                    result="shadowMatrixInner1"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                  />
                  <feGaussianBlur in="SourceAlpha" result="shadowBlurInner2" stdDeviation="8" />
                  <feOffset dy="16" in="shadowBlurInner2" result="shadowOffsetInner2" />
                  <feComposite
                    in="shadowOffsetInner2"
                    in2="SourceAlpha"
                    k2="-1"
                    k3="1"
                    operator="arithmetic"
                    result="shadowInnerInner2"
                  />
                  <feColorMatrix
                    in="shadowInnerInner2"
                    result="shadowMatrixInner2"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                  />
                  <feMerge>
                    <feMergeNode in="shadowMatrixInner1" />
                    <feMergeNode in="shadowMatrixInner2" />
                  </feMerge>
                </filter>
              </defs>
              <g fill="none" fillRule="evenodd">
                <use fill="url(#mask_1_small_b)" xlinkHref="#mask_1_small_c" />
                <use fill="#000" filter="url(#mask_1_small_d)" xlinkHref="#mask_1_small_c" />
              </g>
            </svg>
          </Breakpoint>
        </>
      )}
    </UiContext.Consumer>
  );
};

export default Mask;
