import { useState } from 'react';

const useForm = initialValues => {
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState(initialValues);

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const handleSubmit = event => {
    if (event) event.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...values })
    })
      .then(() => setSuccess(true))
      .catch(error => alert(error));

    event.preventDefault();
  };

  const handleChange = event => {
    event.persist();

    let newValue;
    if (event.target.type === 'checkbox') {
      newValue = event.target.checked;
    } else {
      newValue = event.target.value;
    }

    setValues(values => ({
      ...values,
      [event.target.name]: newValue
    }));
  };

  const handleReset = () => {
    setSuccess(false);
    setValues({ ...values, message: '' });
  };

  return {
    handleChange,
    handleSubmit,
    handleReset,
    values,
    success
  };
};

export default useForm;
